import Invitation from './Components/Invitation';
import './App.css';

function App() {
  return (
	<Invitation />
  );
}

export default App;
